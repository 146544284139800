
      const isProduction = true;
      if (isProduction) {
        if ('serviceWorker' in navigator) {
          // Use the window load event to keep the page load performant
          window.addEventListener('load', () => {
            navigator.serviceWorker.register('/workbox-service-worker.js');
          });
        }
      }
    